// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CorrectAnswer_correctAnswer__AUpqp {\r\n\tmargin-bottom: 35px;\r\n}\r\n.CorrectAnswer_question__KpoWU {\r\n\tfont-weight: bold;\r\n\tpadding-bottom: 2px;\r\n}\r\n.CorrectAnswer_answer__VI143 {\r\n\tfont-style: italic;\r\n}", "",{"version":3,"sources":["webpack://./src/components/CorrectAnswer.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;AACA;CACC,iBAAiB;CACjB,mBAAmB;AACpB;AACA;CACC,kBAAkB;AACnB","sourcesContent":[".correctAnswer {\r\n\tmargin-bottom: 35px;\r\n}\r\n.question {\r\n\tfont-weight: bold;\r\n\tpadding-bottom: 2px;\r\n}\r\n.answer {\r\n\tfont-style: italic;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"correctAnswer": "CorrectAnswer_correctAnswer__AUpqp",
	"question": "CorrectAnswer_question__KpoWU",
	"answer": "CorrectAnswer_answer__VI143"
};
export default ___CSS_LOADER_EXPORT___;

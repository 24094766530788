import React from 'react';
import styles from './CorrectAnswer.module.css';

const CorrectAnswer = (props) => {
	console.log('CorrectAnswer. props är: ', props);
	return (
	  <div className={styles['correctAnswer']}>
		  <p className={styles['question']}>Fråga {props.answer.questionNumber}: {props.answer.question}</p>
		  <p className={styles['answer']}>Rätt svar: {props.answer.correctAnswer}</p>
      </div>
	);
}

export default CorrectAnswer;
import logo from './logo.svg';
import './App.css';
import {Route, Routes, Navigate} from 'react-router-dom';
import {Fragment} from 'react';
import Quiz from "./pages/Quiz";
import Header from './components/layout/Header';
import Layout from "./components/layout/Layout";

function App() {
  return(
    <Layout>
      <Routes>
        <Route path="/" exact element={<Quiz />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}

export default App;

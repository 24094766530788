// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Summary_globalButton__v8zMm {\r\n\tmargin-top: 15px;\r\n\tmargin-right: 20px;\r\n\tbackground-color: royalblue;\r\n\tfont-size: 1.2rem;\r\n\tcolor: white;\r\n\tpadding:  4px 10px 4px 10px;\r\n\tborder-radius: 5px;\r\n\talign-items: center;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Summary.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,kBAAkB;CAClB,2BAA2B;CAC3B,iBAAiB;CACjB,YAAY;CACZ,2BAA2B;CAC3B,kBAAkB;CAClB,mBAAmB;AACpB","sourcesContent":[".globalButton {\r\n\tmargin-top: 15px;\r\n\tmargin-right: 20px;\r\n\tbackground-color: royalblue;\r\n\tfont-size: 1.2rem;\r\n\tcolor: white;\r\n\tpadding:  4px 10px 4px 10px;\r\n\tborder-radius: 5px;\r\n\talign-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalButton": "Summary_globalButton__v8zMm"
};
export default ___CSS_LOADER_EXPORT___;

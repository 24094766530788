import {NavLink} from 'react-router-dom';
import styles from './Header.module.css';

const Header = props => {
	return (
		<header className={styles.header}>
		 	 <h1>Varroatestet</h1>
		</header>
	);
}

export default Header;
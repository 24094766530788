import React from 'react';
import styles from './Summary.module.css';
import CorrectAnswer from './CorrectAnswer';

const returnHandler = () => {
	console.log('returnHandler');
    window.location.replace('https://www.varroadoktorn.se');
}

const Summary = (props) => {
	console.log('Summary. props är: ', props);
	var summaryEnding = 'Här nedanför kan du se vilka frågor du svarade fel på och rätt svar på varje fråga. Hoppas du lärde dig något som du kan ha nytta av.';
	if(props.quizFeedback.score == props.quizFeedback.maxScore) {
		summaryEnding = 'Bra jobbat!';
	}
	return (
	  <div className={styles['summary']}>
		  <h2>Ditt resultat</h2>
		  <p>Du hade {props.quizFeedback.score} rätt av {props.quizFeedback.maxScore} möjliga. 
		  </p>
		  <p>{summaryEnding}</p>
	      {props.quizFeedback.correctAnswers.map((correctAnswer) => (
	      	<CorrectAnswer answer={correctAnswer}></CorrectAnswer>
	      ))}
          <button
            type='button'
            className={styles.globalButton}
            onClick={returnHandler}>
            Tillbaka
          </button>
      </div>
	);
}

export default Summary;
import React, {useState, useEffect, useCallback} from 'react';
import styles from './Quiz.module.css';
import Summary from '../components/Summary';

const Quiz = (props) => {
    const[questionList, setQuestionList] = useState([]);
    const[currentQuestionIndex, setCurrentQuestionIndex] = useState([]);
    const[currentQuestionAnswer, setCurrentQuestionAnswer] = useState([]);
    const[showQuiz, setShowQuiz] = useState(false);
    const[showResult, setShowResult] = useState(false);
    const[quizFeedback, setQuizFeedback] = useState([]);
    const[error, setError] = useState(null);
    const[isLoading, setIsLoading] = useState(false);
//            const serverURLRoot = process.env.REACT_APP_SERVER_URL_ROOT;
        	//TODO ful-fix
	const serverURLRoot = 'https://quiz.varroadoktorn.se';

    const fetchQuizHandler = useCallback( async() => {
    	console.log('getting shit');
    	setCurrentQuestionIndex(0);
        try {
            setError(null);
            setIsLoading(true);
        	//TODO ful-fix
            const url =  serverURLRoot + '/quiz/get';
            console.log('url is: ' + url);
            const response = await fetch(url);
            if(!response.ok) {
                throw new Error('There was an error: ' + response.status);
            }
            const responseData = await response.json();
            console.log("responseData: ", responseData);
            setIsLoading(false);
            const questionList = responseData.questionList;
            console.log(questionList);
    		setCurrentQuestionIndex(0);
            setQuestionList(responseData.questionList);
            setShowQuiz(false);
            setShowResult(false);
        }
        catch(error) {
        	console.log('--- e r r o r ! ---  ', error.message);
    		setCurrentQuestionIndex(0);
            setIsLoading(false);
            setError(error.message);
            setQuestionList([]);
            setShowQuiz(false);
            setShowResult(false);
        }
    }, []);
    const startQuizHandler = () => {
    	setShowQuiz(true);
    }

    const backHandler = () => {
    	if(currentQuestionIndex > 0) {
    		setCurrentQuestionIndex(currentQuestionIndex - 1);
    	}
    }

    const forwardHandler = () => {
    	if(currentQuestionIndex < questionList.length - 1) {
    		setCurrentQuestionIndex(currentQuestionIndex + 1);
    	}
    }

    const postTestHandler = async (event) => {
    	console.log('postTestHandler');
//	    const serverURLRoot = process.env.REACT_APP_SERVER_URL_ROOT;
	    const postQuizUrl = serverURLRoot + '/quiz/post';
	    if(!isLoading) { //to avoid double posts
		    try {
		      let quiz = {
		      	questionList: questionList
		      }
		      setIsLoading(true);
		      console.log("calling: " + postQuizUrl);
	//	      console.log('sending: ', JSON.stringify(quiz));
		      console.log('sending object: ', quiz);
		      const response = await fetch(postQuizUrl,
		      {
		        method:'POST',
		        body:JSON.stringify(quiz),
		        headers: {
		          'Content-type': 'application/json'
		        }
		      });
		      setIsLoading(false);
		      if(response.ok) {
		        console.log('response is OK');
		        const responseData = await response.json();
		        console.log('responseData: ', responseData);
	            setShowQuiz(false);
	            setShowResult(true);
	            setQuizFeedback(responseData);
		      } 
		      else {
		        console.log('There was an error: ' + response.status);
		        const responseErrorData = await response.json();
		        console.log("responseErrorData: ", responseErrorData);
		        if(responseErrorData) {
		          alert("Ett fel har inträffat: " + responseErrorData.errorMessage + ". Felkod: " + responseErrorData.errorCode);
		        } 
		        else {
		          alert("Ett fel har inträffat. Status: " + response.status);
		        }
		        throw new Error('There was an error: ' + response.status);
		      }
		    } 
		    catch(error) {
		      console.log('Ett fel har inträffat');
		      alert(error.message);
		    }
		}
		else {
			console.log("Double post stopped");
		}
    }

    useEffect(() => {
        fetchQuizHandler();
    }, []);

    const answerChangeHandler = (event) => {
	    const newQuestionList = questionList.map((question, i) => {
	      if (i === currentQuestionIndex) {
	      	let replacedQuestion = {...question};
	      	replacedQuestion.answer = event.target.value;
	        return replacedQuestion;
	      } else {
	        return question;
	      }
	    });
    	setQuestionList(newQuestionList);
    }

	return (
	  <div className={styles['quizPage']}>
	      {(() => {
	        if (showQuiz) {
	          return (
	            <div>
	              <h2>Fråga {currentQuestionIndex + 1}:</h2>
	              <h3 className={styles['questionText']}>{questionList[currentQuestionIndex].text}</h3>
	              <div className={styles['rbGroup']}>
	                <div className={styles['radioButton']}>
	        			<input type="radio" 
	        					id='answer_1'
	        					onChange={answerChangeHandler}
	        					checked={questionList[currentQuestionIndex].answer === questionList[currentQuestionIndex].optionList[0].optionKey}
	        		    		value={questionList[currentQuestionIndex].optionList[0].optionKey} 
	        					name="answer" />
	        			<label for="answer_1"> {questionList[currentQuestionIndex].optionList[0].text}</label>
        			</div>
	                <div className={styles['radioButton']}>
	        			<input type="radio" 
	        					id='answer_2'
	        					onChange={answerChangeHandler}
	        					checked={questionList[currentQuestionIndex].answer === questionList[currentQuestionIndex].optionList[1].optionKey}
	        					value={questionList[currentQuestionIndex].optionList[1].optionKey} 
	        					name="answer" />
	        			<label for="answer_2"> {questionList[currentQuestionIndex].optionList[1].text}</label>
        			</div>
	                <div className={styles['radioButton']}>
	        			<input type="radio" 
	        					id='answer_3'
	        					onChange={answerChangeHandler}
	        					checked={questionList[currentQuestionIndex].answer === questionList[currentQuestionIndex].optionList[2].optionKey}
	        					value={questionList[currentQuestionIndex].optionList[2].optionKey} 
	        					name="answer" />
	        			<label for="answer_3"> {questionList[currentQuestionIndex].optionList[2].text}</label>
        			</div>
      			  </div>
      			  <div className={styles.buttons}>
			          <button
			            type='button'
			            className={styles.globalButton}
			            onClick={backHandler}>
			            Föregående
			          </button>
	      			  {currentQuestionIndex < questionList.length-1 ?
				          <button
				            type='button'
				            className={styles.globalButton}
				            onClick={forwardHandler}>
				            Nästa
				          </button>
				      :
				          <button
				            type='button'
				            className={styles.globalButton}
				            onClick={postTestHandler}>
				            Skicka
				          </button>
			      	  }
			      	</div>
	            </div>
	          )
	        } 
	        else if (showResult) {
	        	return (
	        		<Summary quizFeedback={quizFeedback}>
	        			<p>klart</p>
	        		</Summary>
	        	)
	        } 
	        else {
	          return (
	          	<div>
				  <h2>Hur mycket vet du?</h2>
				  <p>Här kan du testa hur mycket du vet om varroa. Du kommer att få 20 frågor med tre svarsalternativ 
				     på varje fråga. Endast ett av alternativen är det rätta svaret.</p>
				  <p>Frågorna och svarsalternativen varierar varje gång du gör testet, så du kan göra det flera gånger</p>
				  <p>Efter den sista frågan får du se hur många rätt du hade. Du får också se vilka frågor du har svarat fel på.</p>
		          <button
		            type='button'
		            className={styles.globalButton}
		            onClick={startQuizHandler}>
		            Börja testet nu
		          </button>
		        </div>
	          )
	        }
	      })()}

      </div>
	);
}

export default Quiz;